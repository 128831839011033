<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div *ngIf="combinedFactories$ | async as combinedFactories">
      <app-factories-list-grid-card
        [factories]="combinedFactories.factories"
        [loading]="combinedFactories.loading"
        [error]="combinedFactories.error"
        [extern]="false"
      ></app-factories-list-grid-card>
    </div>
  </div>
</div>
