<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <div class="card-header">
        <clr-breadcrumb></clr-breadcrumb>
      </div>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div *ngIf="combinedFactory$ | async as combinedFactory">
      <app-factories-detail-item
        [factory]="combinedFactory.factory"
        [loading]="combinedFactory.loading"
        [error]="combinedFactory.error"
      ></app-factories-detail-item>
    </div>
  </div>
</div>
