<div class="card">
  <h6 class="card-header">
    <cds-icon shape="factory" size="md"></cds-icon>
    {{ factory.Nr }} | {{ factory.Name }}
    <span style="float: right">
      <cds-icon shape="details" size="md"></cds-icon>
    </span>
  </h6>

  <div class="card-block">
    <h4 class="card-title" *ngIf="error">
      <div class="alert alert-danger" role="alert">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error.status }} | {{ error.statusText }}
              <ng-container *ngIf="error.error.name">
                | {{ error.error.name }} | {{ error.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </h4>

    <div class="card-text">
      <table class="table table-vertical">
        <tbody>
          <tr>
            <th style="width: 20%">
              <cds-icon shape="hashtag"></cds-icon>&nbsp;Nr
            </th>
            <td>{{ factory.Nr }}</td>
          </tr>
          <tr>
            <th><cds-icon shape="tag"></cds-icon>&nbsp;Name</th>
            <td>{{ factory.Name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- <div class="card-footer">footer</div> -->
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <app-contracts-list-grid-card
      [extern]="true"
      [contracts]="factory.Contracts"
      [loading]="loading"
      [error]="error"
      [hiddenFactory]="true"
    ></app-contracts-list-grid-card>
  </div>
</div>
