import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  ClrBreadcrumbModel,
  ClrBreadcrumbService,
} from '@porscheinformatik/clr-addons';

import { selectProfil } from '../../users/+profil/profil.selectors';

import { selectCombinedFactory } from '../+store/factory.selectors';
import { FactoryActions } from '../+store/factory.actions';

import { Factory } from 'src/app/models/Factory';

@Component({
  selector: 'app-factories-detail',
  templateUrl: './factories-detail.component.html',
  styleUrls: ['./factories-detail.component.css'],
})
export class FactoriesDetailComponent implements OnInit {
  profil$ = this.store.select(selectProfil);

  combinedFactory$: Observable<{
    factory: Factory;
    loading: boolean;
    error: HttpErrorResponse | null;
  }> = new Observable();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private breadcrumbService: ClrBreadcrumbService,
  ) {}

  ngOnInit(): void {
    const breadcrumb1: ClrBreadcrumbModel = { label: 'Home', url: '/' };
    const breadcrumb2: ClrBreadcrumbModel = { label: 'Firmen', url: '../' };
    this.breadcrumbService.updateBreadcrumb([breadcrumb1, breadcrumb2]);

    this.route.params.subscribe((params) => {
      const uid = params['uid'];

      this.profil$.subscribe((profil) => {
        if (profil?._id) {
          this.store.dispatch(FactoryActions.loadFactory({ uid: uid }));
        }
      });

      this.combinedFactory$ = this.store.select(selectCombinedFactory(uid));

      this.combinedFactory$.subscribe((state) => {
        const { factory, loading, error } = state;

        const breadcrumb3: ClrBreadcrumbModel = { label: factory.Name };
        this.breadcrumbService.updateBreadcrumb([
          breadcrumb1,
          breadcrumb2,
          breadcrumb3,
        ]);
      });
    });
  }
}
